import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";

import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { TranslateService } from "@ngx-translate/core";

import { DOCUMENT } from "@angular/common";
import { Project } from "app/shared/models/project.interface";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";

// import { locale as navigationEnglish } from 'app/navigation/i18n/en';
// import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
// import { locale as navigationSpanish } from 'app/navigation/i18n/es';

// var tinycolor = require("tinycolor2");
import tinycolor from "tinycolor2";

@Injectable({
	providedIn: "root",
})
export class ProjectService {
	public TANDEM_PROJECT_ID = 473;

	public projectConfig: Project;
	public adminProjectConfig: Project;
	public projectConfigSubject: Subject<Project>;

	constructor(
		@Inject(DOCUMENT) private document,
		private http: HttpClient,
		private authSvc: AuthService,
		private translateService: TranslateService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private helpersSvc: HelpersService
	) {
		this.projectConfigSubject = new Subject<Project>();
	}

	getProjectDomain(): void {
		const options = {
			headers: new HttpHeaders({
				Authentication: "Basic " + environment.clientSecret,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const body = {
			// hostname: "iberext.feedbalia.com"
			hostname: document.location.hostname,
		};

		this.http.post(environment.urlDomain + "/api/v1/proyectos/domain", body, options).subscribe((response: any) => {
			this.saveProjectId(response.project_id);
			this.projectConfig = response;
			this.projectConfigSubject.next(response);
			localStorage.setItem("projectName", response.name);
			if (response.useNewAesthetic) document.body.classList.add("new-aesthetic");
			localStorage.setItem("entryPointUrl", location.hostname === "localhost" ? "" : response.entryPoint.url);
			localStorage.setItem("entryPointResources", response.entryPoint.resources);
			localStorage.setItem("isLegalTermsEnabled", response.enabledLegalTerms);

			this.generateColorsTheme(response);
			this.setLanguages(response.defaultLanguage, response.supportedLanguages);
		});
	}

	getProjectLegal(project: number) {
		let headers = new HttpHeaders({
			Authentication: "Bearer " + this.authSvc.getAccessToken(),
			responseType: "text",
		});

		const params = {
			language: "es",
		};

		return this.http.get<any>(environment.urlDomain + "/api/v1/proyectos/legal", { headers: headers, params: params }).pipe(
			tap((legal) => {
				return legal;
			})
		);
	}

	private setLanguages(defaultLanguage: string, supportedLanguages: string) {
		const arrayLang = supportedLanguages.split(",");

		// Add languages
		this.translateService.addLangs(arrayLang);

		// Set the default language
		this.translateService.setDefaultLang(defaultLanguage);

		// Set the navigation translations
		// this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish, navigationSpanish);

		// Use a language
		this.translateService.use(defaultLanguage);
	}

	private generateColorsTheme(projectConfig) {
		// projectConfig.themeNavbarBgColor = "#dc131c";
		this.computeColor(projectConfig.themePrimaryColor, "feedbalia-color-primary");
		this.computeColor(projectConfig.themeNavbarBgColor, "feedbalia-color-navbar", projectConfig.themeNavbarTextColor);
		//this.computeColor(projectConfig.themeNavbarBgColor2, 'feedbalia-color-navbar2', projectConfig.themeNavbarTextColor); //FIXME
		this.computeColor(projectConfig.themeNavbarLogoBgColor, "feedbalia-color-navbar-logo", projectConfig.themeNavbarTextColor);
		this.computeColor(projectConfig.themeSidebarBgColor, "feedbalia-color-sidebar", projectConfig.themeSidebarTextColor);

		this.computeColor(projectConfig.themeAccentColor, "feedbalia-color-accent", projectConfig.themePrimaryColor);
		this.computeColor(projectConfig.themeBackgroundColor, "feedbalia-color-background");

		if (projectConfig.id == 472) {
			this.computeColor(projectConfig.themeBackgroundColor, "feedbalia-color-menutext", projectConfig.themeBackgroundColor);
		} else {
			this.computeColor(projectConfig.themeAccentColor, "feedbalia-color-menutext", projectConfig.themeAccentColor);
		}
	}

	private computeColor(color, nameVar, contrast?) {
		if (!color) return;

		const color50 = this.getColorObject(tinycolor(color).lighten(52), contrast);
		const color400 = this.getColorObject(tinycolor(color).lighten(6), contrast);
		const color500 = this.getColorObject(tinycolor(color), contrast);
		const color700 = this.getColorObject(tinycolor(color).darken(12), contrast);

		const el = document.querySelector("body");
		el.style.setProperty("--" + nameVar + "-50", color50.hex);
		el.style.setProperty("--" + nameVar + "-400", color400.hex);
		el.style.setProperty("--" + nameVar + "-500", color500.hex);
		el.style.setProperty("--" + nameVar + "-700", color700.hex);
	}

	private getColorObject(value, contrast?) {
		const c = tinycolor(value);
		const colorObj = {
			hex: c.toHexString(),
			contrast: contrast ? contrast : c.isLight() ? "#000000" : "#ffffff",
		};
		// console.log("colorObj", colorObj);
		return colorObj;
	}

	getProjectId(): string {
		const projectId = localStorage.getItem("projectId");
		return projectId ? projectId : "0";
	}

	getEntryPointUrl(): string {
		const value = localStorage.getItem("entryPointUrl");
		return value ? value : "";
	}

	getEntryPointResources(): string {
		const value = localStorage.getItem("entryPointResources");
		return value ? value : "0";
	}

	getProjectName(): string {
		const value = localStorage.getItem("projectName");
		return value ? value : "";
	}

	private saveProjectId(projectId): void {
		localStorage.setItem("projectId", projectId);
	}

	getProjectConfig(forceRefresh?: boolean, entorno: string = "WEB"): Observable<Project> {
		return new Observable<Project>((observer) => {
			if (!this.projectConfig || forceRefresh) {
				const accessToken = this.authSvc.getAccessToken();

				const options = {
					headers: new HttpHeaders({
						Authentication: "Bearer " + accessToken,
						"Content-Type": "application/json; charset=utf-8",
					}),
				};

				this.http.get(environment.urlDomain + "/api/v1/proyectos/config?" + this.helpersSvc.serializeData({ entorno: entorno }), options).subscribe(
					(response: any) => {
						this.projectConfig = response;
						observer.next(response);
					},
					(err) => {
						// AlertError.showError(err);
					}
				);
			} else {
				observer.next(this.projectConfig);
			}
		});
	}

	getAll(): Observable<Project> {
		return new Observable<Project>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.get(environment.urlDomain + "/api/v1/proyectos", options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					// AlertError.showError(err);
				}
			);
		});
	}

	getUrlAdmin(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/proyectos/adminUrl";
			// observer.next(9390);
			this.http.get(url, options).subscribe(
				(response: any) => {
					if (response) {
						observer.next(response);
					} else {
						observer.error("Se ha producido un error");
					}
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDelegaciones(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/proyectos/delegaciones?id=" + this.getProyectoAdmin();

			this.http.get(url, options).subscribe(
				(response: any) => {
					if (response) {
						observer.next(response);
					} else {
						observer.error("Se ha producido un error");
					}
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPuestos(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/proyectos/puestos";

			this.http.get(url, options).subscribe(
				(response: any) => {
					if (response) {
						observer.next(response);
					} else {
						observer.error("Se ha producido un error");
					}
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDepartamentos(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/proyectos/departamentos";

			this.http.get(url, options).subscribe(
				(response: any) => {
					if (response) {
						observer.next(response);
					} else {
						observer.error("Se ha producido un error");
					}
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	setProyectoAdmin(proyecto) {
		localStorage.setItem("proyectoAdmin", proyecto);
		this.setConfigProyectoAdmin();
		window.location.reload();
	}

	getProyectoAdmin() {
		const value = localStorage.getItem("proyectoAdmin");
		return value ? value : this.projectConfig ? this.projectConfig.id : "";
		//return this.projectConfig ? this.projectConfig.id : "";
	}

	setConfigProyectoAdmin() {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		this.http.get(environment.urlDomain + "/api/v1/proyectos/config/" + this.getProyectoAdmin(), options).subscribe(
			(response: any) => {
				this.adminProjectConfig = response;
			},
			(err) => {
				// AlertError.showError(err);
			}
		);
	}
}
